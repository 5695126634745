<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle class="justify-space-between">
            <span>Создать топик</span>
            <VBtn
              depressed
              color="primary"
              :to="{ name : Names.R_CONTENT_STORAGE_COURSE_TOPICS_EDIT, query : { ...$route.query } }"
            >
              <VIcon left>
                fal fa-chevron-left
              </VIcon>

              Назад к топикам
            </VBtn>
          </VCardTitle>
          <VCardText>
            <VRow>
              <VCol cols="6">
                <VCombobox
                  v-model="key"
                  label="Ключ топика"
                  filled
                  :items="skillTopics"
                  item-text="topicKey"
                  item-value="topicKey"
                  :return-object="false"
                />
              </VCol>
            </VRow>
            <VRow v-if="contents.length">
              <VCol cols="6">
                <h2 class="text-h2">
                  Контент
                </h2>

                <Draggable :list="contents">
                  <div
                    v-for="content in contents"
                    :key="content.id"
                  >
                    <div class="d-flex justify-space-between my-2">
                      <p class="mb-0">
                        {{ content.content.title || 'Нет названия' }}
                      </p>
                      <VBtn
                        icon
                        small
                        color="red"
                        @click="removeContentFromTopic(content.id)"
                      >
                        <VIcon small>
                          fal fa-trash
                        </VIcon>
                      </VBtn>
                    </div>
                    <VDivider />
                  </div>
                </Draggable>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <CourseContentTableCreate @add:content="addContent" />
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VBtn
                  color="primary"
                  depressed
                  x-large
                  @click="updateCourse"
                >
                  Создать топик
                </VBtn>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import omit from 'lodash/omit';
import Draggable from 'vuedraggable';
import { queryParse } from '@front.backoffice/common';
import CourseContentTableCreate from '../../../components/contentStorage/courses/CourseContentTableCreate.vue';

export default {
  name: 'CourseTopicCreate',
  components: { CourseContentTableCreate, Draggable },
  inject: ['Names'],
  data() {
    return {
      key: '',
      contents: [],
      topics: [],
      skillTopics: [],
      cachedCourse: {},
    };
  },
  computed: {
    labels() {
      const { labels = [] } = queryParse(this.$route.query.labels);
      return labels;
    },
  },
  async created() {
    if (!this.$route.query.labels) {
      this.$di.notify.snackError('Отсутствуют метки в адресе');
      return;
    }
    this.getCourse();

    const skillLabel = this.labels.find((label) => label.name === 'skill');

    if (skillLabel) {
      const { topics = [] } = await this.getSkill(skillLabel.value);

      this.skillTopics = topics;
    }
  },
  methods: {
    removeContentFromTopic(id) {
      this.contents = this.contents.filter((item) => item.id !== id);
    },
    getCourse() {
      const payload = {
        labels: this.labels,
        pagination: {
          limit: 100,
        },
      };

      /**
       * TODO: разобраться совместно с бэкендом с этим бардаком
       * Note: Делаем первый запрос для получения topicKeys
       * Это понадобится для запроса всего контента
       */
      this.$di.api.ContentStorage
        .courseItemGet(payload)
        .then((response) => {
          payload.topics = response.topicKeys;
          payload.pagination = {
            ...response.pagintaion,
            limit: response.total,
          };

          /**
           * Note: Делаем второй запрос, чтобы забрать ВЕСЬ контент
           * потому что если бэкенду не прислать его обратно - он удалит весь контент
           */
          this.$di.api.ContentStorage
            .courseItemGet(payload)
            .then((fullCourse) => {
              this.topics = fullCourse.topics ?? [];
              this.cachedCourse = {
                ...fullCourse.course,
              };
            })
            .catch(this.$di.notify.errorHandler);
        })
        .catch(this.$di.notify.errorHandler);
    },
    async getSkill(id) {
      try {
        return await this.$di.api.Assessment.IndexBySkill({ skillId: id });
      } catch (error) {
        this.$di.notify.errorHandler(error);
        return {};
      }
    },
    addContent(contents) {
      this.contents = [...this.contents, contents];
    },
    updateCourse() {
      if (!this.key) {
        this.$di.notify.snackError('Введите ключ топика');
        return;
      }

      if (!this.contents.length) {
        this.$di.notify.snackError('Выберите контент');
        return;
      }

      const topicNew = { key: this.key, items: this.contents };
      const topicsUpdated = [...this.topics, topicNew];
      const topicContentIds = topicsUpdated.map((topic) => {
        // Note: В топике вместо набора контента может ничего не придти (это будет ключ all)
        if (!topic.items) {
          return topic;
        }

        return {
          ...topic,
          items: topic.items.map((item) => item.id),
        };
      });

      const payload = {
        ...omit(this.cachedCourse, ['id', 'topicKeys']),
        topics: topicContentIds,
      };

      this.$di.api.ContentStorage
        .courseItemEdit(payload)
        .then(() => this.goToTopicEdit())
        .catch(this.$di.notify.errorHandler);
    },
    goToTopicEdit() {
      this.$router.push({
        name: this.Names.R_CONTENT_STORAGE_COURSE_TOPICS_CONTENT_EDIT,
        params: { topicKey: this.key },
        query: { ...this.$route.query },
      });
    },
  },
};
</script>
