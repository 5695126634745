<template>
  <VDataTable
    v-model="selected"
    :headers="headers"
    :items="items"
    :server-items-length="pagination.total"
    :loading="contentLoading"
    @pagination="handlePagination"
  >
    <template #item.name="{ item }">
      <h3 class="title">
        {{ item.content.title }}
      </h3>
      <p class="mb-0">
        {{ item.id }}
      </p>
    </template>

    <template #item.type="{ item }">
      <h4>
        {{ item.contentType }}
      </h4>
    </template>

    <template #item.content="{ item }">
      <h4>
        {{ item.content.body }}
      </h4>
    </template>

    <template #item.status="{ item }">
      <template v-if="contentTypes.videoHLS === item.contentType">
        <VBtn
          v-if="CONTENT_STATUS.STATUS_ERROR === item.status"
          depressed
          icon
          color="warning"
          @click="reuploadContent(item.id)"
        >
          <VIcon>fal fa-redo</VIcon>
        </VBtn>
        <p
          v-else
          class="mb-0"
        >
          {{ item.status || 'Нет статуса' }}
        </p>
      </template>
    </template>

    <template #item.actions="{ item }">
      <VBtn
        depressed
        fab
        small
        icon
        @click="addContent(item)"
      >
        <VIcon color="green">
          far fa-plus
        </VIcon>
      </VBtn>
    </template>
  </VDataTable>
</template>

<script>
import { contentTypes, CONTENT_STATUS } from './common';

export default {
  name: 'CourseContentTableCreate',
  data() {
    return {
      selected: [],
      contentLoading: false,
      items: [],
      pagination: {
        offset: 0,
        limit: 0,
        total: 0,
        /** Note: page нужен для перезагрузки текущей страницы */
        page: 0,
      },
      CONTENT_STATUS,
      contentTypes,
      defaultPagination: { itemsLength: 0, itemsPerPage: 10, page: 1 },
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Тип контента',
          align: 'left',
          sortable: false,
          value: 'type',
        },
        {
          text: 'Контент',
          align: 'left',
          sortable: false,
          value: 'content',
        },
        {
          text: 'Статус',
          align: 'left',
          sortable: false,
          value: 'status',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
  methods: {
    addContent(content) {
      this.$emit('add:content', content);
    },
    async handlePagination({ itemsLength, itemsPerPage, page }) {
      this.contentLoading = true;

      const limit = itemsPerPage === -1 ? itemsLength : itemsPerPage;
      const offset = page > 0 ? (page - 1) * limit : 0;

      this.$di.api.ContentStorage
        .contentItemOrphaned({ pagination: { limit, offset } })
        .then((response) => {
          if (response && response.items && Array.isArray(response.items)) {
            this.items = response.items;
          }

          this.pagination = {
            total: response.total || 0,
            offset: response.pagination.offset || 0,
            limit: response.pagination.limit || 0,
            page,
          };
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.contentLoading = false;
        });
    },
    reuploadContent(id) {
      this.$di.api.ContentStorage
        .contentItemVideoUploaded({ id })
        .then(() => {
          const pagination = {
            itemsLength: this.pagination.total,
            itemsPerPage: this.pagination.limit,
            page: this.pagination.page,
          };

          this.handlePagination(pagination);
        })
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
